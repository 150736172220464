import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Paper, Typography } from '@material-ui/core'

import {
	Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineOppositeContent, TimelineDot
} from '@material-ui/lab';

import engagement from '../../images/timeline/engagement.png'
import church from '../../images/timeline/church.svg'
import hogar from '../../images/timeline/hogarIcon.png'
import carnival from '../../images/timeline/carnival.png'


const useStyles = makeStyles((theme) => ({
	paper: {
		padding: '6px 16px',
		"&:hover,&:focus": {
			boxShadow: theme.shadows[24],
		},
	},

	secondaryTail: {
		backgroundColor: theme.palette.secondary.main,
	},
	avatarLarge: {
		width: theme.spacing(7),
		height: theme.spacing(7),
		"&:hover,&:focus": {
			boxShadow: theme.shadows[24],
		},
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
}));

const avatar = (classes, image) => (<Avatar
	alt="Remy Sharp"
	className={classes.avatarLarge}
	src={image}
/>)

export default function TimelineNuestraHistoria() {
	const classes = useStyles();
	// const [inicio, setInicio] = useState(false)
	return (

		<Timeline align="alternate">
			<TimelineItem>
				<TimelineOppositeContent />
				<TimelineSeparator color="primary" variant="outlined">
					<TimelineDot color="primary" variant="outlined">
						{ avatar(classes, carnival)}
					</TimelineDot>
					<TimelineConnector />
				</TimelineSeparator>
				<TimelineContent>
					<Paper elevation={3} className={classes.paper}>
						<Typography variant="h6" component="h1">
							2 Marzo 2019
						</Typography>
						<Typography>Todo comenzo en carnaval</Typography>
					</Paper>
				</TimelineContent>
			</TimelineItem>

			<TimelineItem>
				<TimelineOppositeContent />
				<TimelineSeparator color="primary" variant="outlined">
					<TimelineDot color="primary" variant="outlined">
						{ avatar(classes, hogar)}
					</TimelineDot>
					<TimelineConnector />
				</TimelineSeparator>
				<TimelineContent>
					<Paper elevation={3} className={classes.paper}>
						<Typography variant="h6" component="h1">
							1 Diciembre 2019
						</Typography>
						<Typography>¡Nos mudamos juntos!</Typography>
					</Paper>
				</TimelineContent>
			</TimelineItem>

			<TimelineItem>
				<TimelineOppositeContent />
				<TimelineSeparator>
					<TimelineDot color="primary" variant="outlined">
						{ avatar(classes, engagement)}
					</TimelineDot>
					<TimelineConnector className={classes.secondaryTail} />
				</TimelineSeparator>
				<TimelineContent>
					<Paper elevation={3} className={classes.paper}>
						<Typography variant="h6" component="h1">
							29 Julio 2020
						</Typography>
						<Typography>Nos comprometimos</Typography>
					</Paper>
				</TimelineContent>
			</TimelineItem>

			<TimelineItem>
				<TimelineOppositeContent />
				<TimelineSeparator>
					<TimelineDot color="primary" variant="outlined">
						{ avatar(classes, church)}
					</TimelineDot>
				</TimelineSeparator>
				<TimelineContent>
					<Paper elevation={3} className={classes.paper}>
						<Typography variant="h6" component="h1">
							2 Octubre 2021
						</Typography>
						<Typography>¡Nos vamos a casar!!</Typography>
					</Paper>
				</TimelineContent>
			</TimelineItem>
		</Timeline>
	);
}
